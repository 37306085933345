<template>
	<div class="footer">
		<p class="footer_title">{{ $title.cn }}</p>
		<p class="footer_title_en">{{ $title.en }}</p>
	</div>
</template>

<script>
	export default {
		name: "index"
	}
</script>

<style lang="less" scoped>
	@import '~@/assets/fonts/fonts.css';

	.footer {
		width: 100%;
		height: @webFooterHeight;
		background-color: @greenLightColor;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: #ffffff;
		font-family: 'shangshushui';
	}

	.footer_title {
		font-size: 4.4rem;
	}

	.footer_title_en {
		font-size: 1rem;
		margin-top: 0.5rem;
	}






	@media screen and (max-width: 800px) {
		.footer {
			height: @phoneFooterHeight;
		}

		.footer_title {
			font-size: 2rem;
		}

		.footer_title_en {
			font-size: 0.6rem;
		}
	}
</style>