<template>
	<div class="wrap">
		<v-header></v-header>

		<div class="content_wrap">
			<router-view></router-view>
		</div>

		<v-footer></v-footer>
	</div>
</template>

<script>
	import VHeader from './header'
	import VFooter from './footer'

	export default {
		name: "index",
		components: {
			VHeader,
			VFooter
		},
		computed: {

		}
	}
</script>

<style lang="less" scoped>
	.wrap {
		width: 100%;
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
	}

	.content_wrap {
		width: 100%;
		min-height: calc(100% - @webHeadNavHeight - @webHeadSwipeHeight - @webFooterHeight);
	}





	@media screen and (max-width: 800px) {
		.content_wrap {
			min-height: calc(100% - @phoneHeadNavHeight - @phoneHeadSwipeHeight - @phoneFooterHeight);
		}
	}
</style>
