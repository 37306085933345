<template>
	<div class="head">
		<div class="head_nav">
			<div class="head_nav_title">
				<img :src="$import('logo.png')" alt="">
				<span class="head_nav_title_line"></span>
				<p class="head_nav_title_con">
					<span class="head_nav_title_cn">{{ $title.cn }}</span>
					<span class="head_nav_title_en">VILLAGE HIGH END FACILITY AGRICULTURAL DEMONSTRATION PARK</span>
				</p>
			</div>

			<div class="head_nav_con">
				<template v-for="(item, index) in navList">
					<span v-if="index !== 0" class="head_nav_con_line">/</span>
					<span class="head_nav_con_item" :class="{'head_nav_con_item_active': navActive == index}" @click="navClick(item, index)">{{ item.title }}</span>
				</template>
			</div>
		</div>

		<div class="head_swipe_box">
			<van-swipe ref="swipe" class="head_swipe" :autoplay="3000" indicator-color="white">
				<van-swipe-item v-for="(item, index) in swipeList" :key="index">
					<van-image width="100%" height="100%" fit="cover" lazy-load :src="item.url" />
				</van-swipe-item>
			</van-swipe>

			<van-icon @click="prev" class="head_swipe_btn head_swipe_left" name="arrow-left" />
			<van-icon  @click="next" class="head_swipe_btn head_swipe_right" name="arrow" />
		</div>

	</div>
</template>

<script>
	export default {
		name: "index",
		data() {
			return {
				navActive: 0,
				navList: [
					{title: "首页", url: "/index"},
					{title: "产品中心", url: "/product"},
					{title: "新闻动态", url: "/news"},
					{title: "园区风采", url: "/scenery"},
					{title: "关于我们", url: "/about"}
				],
				swipeList: []
			}
		},
		created() {
			let path = this.$route.path;
			let index = this.navList.findIndex(item => item.url.indexOf(path) != -1);
			this.navActive = index;

			this.init();
		},
		methods: {
			async init() {
				const res = await this.$api.carousel();
				this.swipeList = res.data;
			},

			/*
			* 导航点击
			* */
			navClick(item, index) {
				this.navActive = index;
				this.$router.push(item.url);
			},

			/*
			* 上一张 轮播图
			* */
			prev() {
				this.$refs.swipe.prev();
			},

			/*
			* 下一张 轮播图
			* */
			next() {
				this.$refs.swipe.next();
			}
		}
	}
</script>

<style lang="less" scoped>
	@import '~@/assets/fonts/fonts.css';

	.head {
		width: 100%;
	}

	.head_nav {
		width: 100%;
		height: @webHeadNavHeight;
		display: flex;
		overflow: hidden;
	}

	.head_nav_title {
		flex-shrink: 0;
		width: 50%;
		color: #4a4a4a;
		font-family: 'shangshushui';
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.head_nav_title img {
		width: 10rem;
	}

	.head_nav_title_line {
		width: 1px;
		height: 35%;
		background-color: #4a4a4a;
		margin: 0 2rem;
	}

	.head_nav_title_con {
		display: flex;
		flex-direction: column;
		text-align: center;
	}

	.head_nav_title_cn {
		font-size: 3.2rem;
	}

	.head_nav_title_en {
		font-size: 1.07rem;
	}

	.head_nav_con {
		flex-shrink: 0;
		width: 50%;
		padding: 0 5%;
		font-size: 2rem;
		color: #ffffff;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: @greenColor;
		user-select: none;
	}

	.head_nav_con_item {
		cursor: pointer;
	}

	.head_nav_con_item_active {
		color: #000000;
	}

	.head_swipe_box {
		height: @webHeadSwipeHeight;
		position: relative;
	}

	.head_swipe {
		height: 100%;
	}

	.head_swipe /deep/ .van-swipe__indicator {
		width: 1.8rem;
		height: 1.8rem;
	}

	.head_swipe /deep/ .van-swipe__indicator:not(:last-child) {
		margin-right: 1.8rem;
	}

	.head_swipe_box:hover .head_swipe_btn {
		opacity: 1;
	}

	.head_swipe_btn {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		font-size: 7rem;
		z-index: 999;
		color: #ffffff;
		cursor: pointer;
		opacity: 0;
		transition: all .5s;
	}

	.head_swipe_left {
		left: 0;
	}

	.head_swipe_right {
		right: 0;
	}





	@media screen and (max-width: 900px){
		.head_nav_title {
			width: auto;
			font-size: 3rem;
			padding: 0 1rem;
		}

		.head_nav_con {
			width: 100%;
			flex-shrink: 1;
		}
	}




	@media screen and (max-width: 800px){
		.head {
			display: flex;
			flex-direction: column-reverse;
		}

		.head_swipe_box {
			height: @phoneHeadSwipeHeight;
		}

		.head_swipe_btn {
			display: none;
		}

		.head_swipe /deep/ .van-swipe__indicator {
			width: 1rem;
			height: 1rem;
		}

		.head_swipe /deep/ .van-swipe__indicator:not(:last-child) {
			margin-right: 1rem;
		}

		.head_nav_title {
			display: none;
		}

		.head_nav {
			height: @phoneHeadNavHeight;
		}

		.head_nav_con {
			font-size: 1.2rem;
		}
	}
</style>